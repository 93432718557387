



















































import { Component, Vue, Ref } from "vue-property-decorator";
import api from "@/api";
import { ProductConsultationsDto } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";

@Component({
  components: {
    PagedTableView,
  },
})
export default class UserWorkDeveloper extends Vue {
  detailId = 0;
  queryForm = {
    userName: "",
    mobilePhone: "",
    orgName: "",
  };

  fetchData(params: any) {
    return api.productConsultations.getAll(params);
  }

  jumpDetail(index: number, row: ProductConsultationsDto) {
    this.detailId = row.id!;
    this.$router.push({
      name: "productConsultationDetail",
      params: { id: row.id!.toString() },
    });
  }
}
